:root {
    --shadow: 0px 23px 21px -8px rgba(136, 160, 255, .25);
    --blue: rgb(20, 20, 218);
    --lightBlue: #eeeeff;
    --primary: #1f3e72;
    --secondary: rgba(255, 255, 255, 0.78);
    --black: #131110;
    --blue-gradient: linear-gradient(97.05deg, #4066ff 3.76%, #2c29e7);
    --orange-gradient: linear-gradient(97.05deg, orange 3.76%, #cfcfdb);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "popins", sans-serif;
    scroll-behavior: smooth;
}

.primaryText {
    color: #1f3e72;
    font-weight: bold;
    font-size: 2rem;
}

.secondaryText {
    color: rgb(140, 139, 139);
    font-size: 0.9rem;
}

.orangeText {
    color: orange;
    font-size: 1.5rem;
    font-weight: 600;
}

a {
    color: inherit;
    text-decoration: none;
}

.paddings {
    padding: 1.5rem;
}

.innerWidth {
    width: 100%;
}

.flexCenter {
    display: flex;
    row-gap: 2rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.flexstart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flexEnd {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flexColStart {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.flexColCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.button {
    font-weight: 500;
    padding: 0.6rem 1.4rem;
    color: white;
    background: var(--blue-gradient);
    border: none;
    border-radius: 4px;
    transition: all 300ms ease-in;
}

.button:hover {
    cursor: pointer;
    transform: scale(1.1);
}


/*Media Qurries*/

@media (min-width:1536px) {
    .innerWidth {
        max-width: 1280px;
        margin: auto;
    }
}

@media(min-width:640px) {
    .paddings {
        padding: 4rem;
    }
}

@media(min-width:1280px),
(min-width:768px) {
    .paddings {
        padding: 2rem;
    }
}

@media(max-width:640px) {
    .primaryText {
        font-size: 1.5rem;
    }
    .orangeText {
        font-size: 1.2rem;
    }
}