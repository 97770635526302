.App {
    position: relative;
    overflow-x: clip;
}

.App>:nth-child(1) {
    background-color: var(--black);
    position: relative;
}

.white-gradient {
    position: absolute;
    width: 15rem;
    height: 15rem;
    background: rgba(255, 255, 255, 0.522);
    filter: blur(100px);
    border-radius: 100px;
}